<template>
  <div class="case-statistics-container">
    <div class="measure-count">{{ t('PR001.measurementCount') }}</div>
    <div class="abnormal-percentage">{{ t('PR001.abnormalPercentage') }}</div>

    <div class="info-block-title-1">{{ t('PR001.bloodPressureCaptial') }}</div>
    <div class="info-block-title-2">{{ t('PR001.glucoseCaptial') }}</div>
    <div class="info-block-title-3">{{ t('PR001.bodyWeightCaptial') }}</div>
    <div class="info-block-title-4">{{ t('PR001.bodyFatCaptial') }}</div>
    <div class="info-block-title-5">{{ t('PR001.visceralFatCaptial') }}</div>

    <div class="info-block-1">
      <div class="bloodPressure-count">
        {{
          measurementsAbnormalRate?.countOfMeasurements?.bloodPressureCount
            ? measurementsAbnormalRate?.countOfMeasurements?.bloodPressureCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
      </div>
      <div class="bloodPressure-percentage">
        {{
          measurementsAbnormalRate?.abnormalPercentage?.bloodPressure
            ? measurementsAbnormalRate?.abnormalPercentage?.bloodPressure
            : 0
        }}
      </div>
      <div class="bloodPressure-arr-title">
        ARR
        <br />
        ARR AFib
        <br />
        ARR PC
        <br />
        ARR Afib PC
      </div>
      <div class="bloodPressure-arr">
        {{
          measurementsAbnormalRate.arrCount
            ? measurementsAbnormalRate.arrCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
        <br />
        {{
          measurementsAbnormalRate.arrAfibCount
            ? measurementsAbnormalRate.arrAfibCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
        <br />
        {{
          measurementsAbnormalRate.arrPcCount
            ? measurementsAbnormalRate.arrPcCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
        <br />
        {{
          measurementsAbnormalRate?.arrAfibPcCount
            ? measurementsAbnormalRate?.arrAfibPcCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
      </div>
    </div>
    <div class="info-block-2">
      <div class="glucose-count">
        {{
          measurementsAbnormalRate?.countOfMeasurements?.bloodGlucoseCount
            ? measurementsAbnormalRate?.countOfMeasurements?.bloodGlucoseCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
      </div>
      <div class="glucose-percentage">
        {{
          measurementsAbnormalRate?.abnormalPercentage?.bloodGlucose
            ? measurementsAbnormalRate?.abnormalPercentage?.bloodGlucose
            : 0
        }}
      </div>
    </div>
    <div class="info-block-3">
      <div class="bodyWeight-count">
        {{
          measurementsAbnormalRate?.countOfMeasurements?.weightCount
            ? measurementsAbnormalRate?.countOfMeasurements?.weightCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
      </div>
      <div class="bodyWeight-percentage">
        {{
          measurementsAbnormalRate?.abnormalPercentage?.weight
            ? measurementsAbnormalRate?.abnormalPercentage?.weight
            : 0
        }}
      </div>
    </div>
    <div class="info-block-4">
      <div class="bodyFat-count">
        {{
          measurementsAbnormalRate?.countOfMeasurements?.bodyFatCount
            ? measurementsAbnormalRate?.countOfMeasurements?.bodyFatCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
      </div>
      <div class="bodyFat-percentage">
        {{
          measurementsAbnormalRate?.abnormalPercentage?.bodyFat
            ? measurementsAbnormalRate?.abnormalPercentage?.bodyFat
            : 0
        }}
      </div>
    </div>
    <div class="info-block-5">
      <div class="visceralFat-count">
        {{
          measurementsAbnormalRate?.countOfMeasurements?.visceralFatCount
            ? measurementsAbnormalRate?.countOfMeasurements?.visceralFatCount
            : 0
        }}
        {{ t('PR001.countUnit') }}
      </div>
      <div class="visceralFat-percentage">
        {{
          measurementsAbnormalRate?.abnormalPercentage?.visceralFat
            ? measurementsAbnormalRate?.abnormalPercentage?.visceralFat
            : 0
        }}
      </div>
    </div>

    <div class="average-title-area">
      <div class="average-title-block-1"></div>
      <div class="average-title-block-2"></div>
      <div class="average-title-block-3"></div>
      <div class="average-title-1">{{ t('PR001.systolic') }}</div>
      <div class="average-title-2">{{ t('PR001.diastolic') }}</div>
      <div class="average-title-3">{{ t('PR001.pp') }}</div>
      <div class="average-title-4">{{ t('PR001.pulse') }}</div>
      <div class="average-title-5">{{ t('PR001.fasting') }}</div>
      <div class="average-title-6">{{ t('PR001.beforeMeal') }}</div>
      <div class="average-title-7">{{ t('PR001.afterMeal') }}</div>
      <div class="average-title-8">{{ t('PR001.bodyWeight') }}</div>
      <div class="average-title-9">{{ t('PR001.bmi') }}</div>
      <div class="average-title-10">{{ t('PR001.bodyFat') }}</div>
      <div class="average-title-11">{{ t('PR001.musclePercentage') }}</div>
      <div class="average-title-12">{{ t('PR001.visceralFat') }}</div>
      <div class="average-title-13">{{ t('PR001.bmr') }}</div>
      <div class="average-title-14">{{ t('PR001.spo2') }}</div>
      <div class="average-title-15">{{ t('PR001.act') }}</div>
      <div class="average-title-16">{{ t('PR001.bodyTemperature') }}</div>
    </div>
    <div class="average-content-area">
      <div class="average-content-block-1"></div>
      <div class="average-content-block-2"></div>
      <div class="average-content-block-3"></div>
      <div class="average-content-title">{{ t('PR001.average') }}</div>
      <div
        class="average-content-1"
        :class="{
          'normal-color':
            measurementsAvg.systolicWarningLevel == 0 ||
            measurementsAvg.systolicAVG == null,
          'warning-color':
            measurementsAvg.systolicWarningLevel == 1 &&
            measurementsAvg.systolicAVG != null,
          'abnormal-color':
            measurementsAvg.systolicWarningLevel == 2 &&
            measurementsAvg.systolicAVG != null,
        }"
      >
        {{ getSystolicAVG() }}
      </div>
      <div
        class="average-content-2"
        :class="{
          'normal-color':
            measurementsAvg.diastolicWarningLevel == 0 ||
            measurementsAvg.diastolicAVG == null,
          'warning-color':
            measurementsAvg.diastolicWarningLevel == 1 &&
            measurementsAvg.diastolicAVG != null,
          'abnormal-color':
            measurementsAvg.diastolicWarningLevel == 2 &&
            measurementsAvg.diastolicAVG != null,
        }"
      >
        {{ getDiastolicAVG() }}
      </div>
      <div
        class="average-content-3"
        :class="{
          'normal-color':
            measurementsAvg.ppWarningLevel == 0 ||
            measurementsAvg.ppAVG == null,
          'warning-color':
            measurementsAvg.ppWarningLevel == 1 &&
            measurementsAvg.ppAVG != null,
          'abnormal-color':
            measurementsAvg.ppWarningLevel == 2 &&
            measurementsAvg.ppAVG != null,
        }"
      >
        {{ getPpAVG() }}
      </div>
      <div
        class="average-content-4"
        :class="{
          'normal-color':
            measurementsAvg.pulseWarningLevel == 0 ||
            measurementsAvg.pulseAVG == null,
          'warning-color':
            measurementsAvg.pulseWarningLevel == 1 &&
            measurementsAvg.pulseAVG != null,
          'abnormal-color':
            measurementsAvg.pulseWarningLevel == 2 &&
            measurementsAvg.pulseAVG != null,
        }"
      >
        {{
          measurementsAvg.pulseAVG
            ? roundDecimal(measurementsAvg.pulseAVG, 1)
            : '--'
        }}
      </div>
      <div
        class="average-content-5"
        :class="{
          'normal-color':
            measurementsAvg.bloodGlucoseFastingWarningLevel == 0 ||
            measurementsAvg.fastingAVG == null,
          'warning-color':
            measurementsAvg.bloodGlucoseFastingWarningLevel == 1 &&
            measurementsAvg.fastingAVG != null,
          'abnormal-color':
            measurementsAvg.bloodGlucoseFastingWarningLevel == 2 &&
            measurementsAvg.fastingAVG != null,
        }"
      >
        {{ getFastingAVG() }}
      </div>
      <div
        class="average-content-6"
        :class="{
          'normal-color':
            measurementsAvg.bloodGlucoseBeforeMealWarningLevel == 0 ||
            measurementsAvg.beforeMealAVG == null,
          'warning-color':
            measurementsAvg.bloodGlucoseBeforeMealWarningLevel == 1 &&
            measurementsAvg.beforeMealAVG != null,
          'abnormal-color':
            measurementsAvg.bloodGlucoseBeforeMealWarningLevel == 2 &&
            measurementsAvg.beforeMealAVG != null,
        }"
      >
        {{ getBeforeMealAVG() }}
      </div>
      <div
        class="average-content-7"
        :class="{
          'normal-color':
            measurementsAvg.bloodGlucoseAfterMealWarningLevel == 0 ||
            measurementsAvg.afterMealAVG == null,
          'warning-color':
            measurementsAvg.bloodGlucoseAfterMealWarningLevel == 1 &&
            measurementsAvg.afterMealAVG != null,
          'abnormal-color':
            measurementsAvg.bloodGlucoseAfterMealWarningLevel == 2 &&
            measurementsAvg.afterMealAVG != null,
        }"
      >
        {{ getAfterMealAVG() }}
      </div>
      <div class="average-content-8 normal-color">
        {{
          measurementsAvg.weightAVG
            ? roundDecimal(measurementsAvg.weightAVG, 2)
            : '--'
        }}
      </div>
      <div
        class="average-content-9"
        :class="{
          'normal-color':
            measurementsAvg.bmiWarningLevel == 0 ||
            measurementsAvg.bmiAVG == null,
          'warning-color':
            measurementsAvg.bmiWarningLevel == 1 &&
            measurementsAvg.bmiAVG != null,
          'abnormal-color':
            measurementsAvg.bmiWarningLevel == 2 &&
            measurementsAvg.bmiAVG != null,
        }"
      >
        {{
          measurementsAvg.bmiAVG
            ? roundDecimal(measurementsAvg.bmiAVG, 1)
            : '--'
        }}
      </div>
      <div
        class="average-content-10"
        :class="{
          'normal-color':
            measurementsAvg.bodyFatWarningLevel == 0 ||
            measurementsAvg.bodyFatAVG == null,
          'warning-color':
            measurementsAvg.bodyFatWarningLevel == 1 &&
            measurementsAvg.bodyFatAVG != null,
          'abnormal-color':
            measurementsAvg.bodyFatWarningLevel == 2 &&
            measurementsAvg.bodyFatAVG != null,
        }"
      >
        {{
          measurementsAvg.bodyFatAVG
            ? roundDecimal(measurementsAvg.bodyFatAVG, 1)
            : '--'
        }}
      </div>
      <div class="average-content-11 normal-color">
        {{ getMuscleMassAvg() }}
      </div>
      <div
        class="average-content-12"
        :class="{
          'normal-color':
            measurementsAvg.visceralFatWarningLevel == 0 ||
            measurementsAvg.visceralFatAVG == null,
          'warning-color':
            measurementsAvg.visceralFatWarningLevel == 1 &&
            measurementsAvg.visceralFatAVG != null,
          'abnormal-color':
            measurementsAvg.visceralFatWarningLevel == 2 &&
            measurementsAvg.visceralFatAVG != null,
        }"
      >
        {{
          measurementsAvg.visceralFatAVG
            ? Math.floor(measurementsAvg.visceralFatAVG)
            : '--'
        }}
      </div>
      <div class="average-content-13 normal-color">
        {{
          measurementsAvg.bmrAVG
            ? roundDecimal(measurementsAvg.bmrAVG, 0)
            : '--'
        }}
      </div>
      <div
        class="average-content-14"
        :class="{
          'normal-color':
            measurementsAvg.spo2WarningLevel == 0 ||
            measurementsAvg.spo2AVG == null,
          'warning-color':
            measurementsAvg.spo2WarningLevel == 1 &&
            measurementsAvg.spo2AVG != null,
          'abnormal-color':
            measurementsAvg.spo2WarningLevel == 2 &&
            measurementsAvg.spo2AVG != null,
        }"
      >
        {{
          measurementsAvg.spo2AVG
            ? roundDecimal(measurementsAvg.spo2AVG, 0)
            : '--'
        }}
      </div>
      <div class="average-content-15 normal-color">
        {{
          measurementsAvg.actAVG
            ? roundDecimal(measurementsAvg.actAVG, 0)
            : '--'
        }}
      </div>
      <div
        class="average-content-16"
        :class="{
          'normal-color':
            measurementsAvg.bodyTemperatureWarningLevel == 0 ||
            measurementsAvg.bodyTemperatureAVG == null,
          'warning-color':
            measurementsAvg.bodyTemperatureWarningLevel == 1 &&
            measurementsAvg.bodyTemperatureAVG != null,
          'abnormal-color':
            measurementsAvg.bodyTemperatureWarningLevel == 2 &&
            measurementsAvg.bodyTemperatureAVG != null,
        }"
      >
        {{
          measurementsAvg.bodyTemperatureAVG
            ? roundDecimal(measurementsAvg.bodyTemperatureAVG, 1)
            : '--'
        }}
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { roundDecimal } from '@/utils/pocketKnives'

  export default defineComponent({
    components: {},
    props: {
      fid: {
        type: String,
        default: '',
      },
      fromDateTs: {
        type: Number,
        default: 0,
      },
      toDateTs: {
        type: Number,
        default: 0,
      },
      refresh: {
        type: Number,
        default: 0,
      },
    },
    setup(props) {
      const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
      const store = useStore()

      // Vuex action mapping
      const getMeasurementsAvg = (queryParams) =>
        store.dispatch('measurement/getMeasurementsAvg', queryParams)
      const getMeasurementsAbnormalRate = (queryParams) =>
        store.dispatch('measurement/getMeasurementsAbnormalRate', queryParams)
      const getMeasureThreshold = () =>
        store.dispatch('measureThreshold/getMeasureThreshold')
      const measurementsAvg = computed(
        () => store.state.measurement.measurementsAvg
      )
      const measurementsAbnormalRate = computed(
        () => store.state.measurement.measurementsAbnormalRate
      )
      const measureThreshold = computed(
        () => store.state.measureThreshold.measureThreshold
      )

      onMounted(() => {
        if (props.fid && props.fromDateTs > 0 && props.toDateTs > 0) {
          const queryParams = {
            startTs: props.fromDateTs,
            endTs: props.toDateTs,
            fid: props.fid,
          }
          getMeasureThreshold()
          getMeasurementsAvg(queryParams)
          getMeasurementsAbnormalRate(queryParams)
        }
      })

      // const fromTs = ref(props.fromDateTs)
      // const toTs = ref(props.toDateTs)

      watch(
        () => props.refresh,
        (current, prev) => {
          // console.log('--watch 1')
          // console.log(props)
          console.log(prev, current)
          if (props.fid && props.fromDateTs > 0 && props.toDateTs > 0) {
            const queryParams = {
              startTs: props.fromDateTs,
              endTs: props.toDateTs,
              fid: props.fid,
            }
            getMeasurementsAvg(queryParams)
            getMeasurementsAbnormalRate(queryParams)
          }
        }
      )

      const getSystolicAVG = () => {
        let roundDecimalParam = 0
        if (measureThreshold.value?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return measurementsAvg.value.systolicAVG
          ? roundDecimal(measurementsAvg.value.systolicAVG, roundDecimalParam)
          : '--'
      }
      const getDiastolicAVG = () => {
        let roundDecimalParam = 0
        if (measureThreshold.value?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return measurementsAvg.value.diastolicAVG
          ? roundDecimal(measurementsAvg.value.diastolicAVG, roundDecimalParam)
          : '--'
      }
      const getPpAVG = () => {
        let roundDecimalParam = 0
        if (measureThreshold.value?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return measurementsAvg.value.ppAVG
          ? roundDecimal(measurementsAvg.value.ppAVG, roundDecimalParam)
          : '--'
      }
      const getFastingAVG = () => {
        let roundDecimalParam = 0
        if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }
        return measurementsAvg.value.fastingAVG
          ? roundDecimal(measurementsAvg.value.fastingAVG, roundDecimalParam)
          : '--'
      }
      const getBeforeMealAVG = () => {
        let roundDecimalParam = 0
        if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return measurementsAvg.value.beforeMealAVG
          ? roundDecimal(measurementsAvg.value.beforeMealAVG, roundDecimalParam)
          : '--'
      }
      const getAfterMealAVG = () => {
        let roundDecimalParam = 0
        if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }
        return measurementsAvg.value.afterMealAVG
          ? roundDecimal(measurementsAvg.value.afterMealAVG, roundDecimalParam)
          : '--'
      }
      const getMuscleMassAvg = () => {
        return measurementsAvg.value?.muscleMassAVG &&
          measurementsAvg.value?.weightAVG
          ? roundDecimal(
              (measurementsAvg.value?.muscleMassAVG /
                measurementsAvg.value?.weightAVG) *
                100,
              1
            )
          : '--'
      }

      return {
        t,
        measurementsAvg,
        measurementsAbnormalRate,
        roundDecimal,
        getMeasureThreshold,
        getSystolicAVG,
        getDiastolicAVG,
        getPpAVG,
        getFastingAVG,
        getBeforeMealAVG,
        getAfterMealAVG,
        getMuscleMassAvg,
      }
    },
  })
</script>

<style lang="less" scoped>
  .normal-color {
    color: #ffffff;
  }
  .warning-color {
    color: #ffc700;
  }
  .abnormal-color {
    color: #ff0000;
  }
  .title-font() {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height, or 89% */

    display: flex;
    align-items: center;

    color: #5b5b5b;
  }
  .info-block-title() {
    background: #d2e4ca;
    border-radius: 5px 5px 0px 0px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #5b5b5b;
  }
  .info-block() {
    background: rgba(166, 217, 151, 0.15);
    border-radius: 0px 5px 5px 5px;
  }
  .arr-font() {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* or 123% */

    display: flex;
    align-items: center;

    color: #8b2331;
  }
  .average-title-font() {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    /* or 123% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #5b5b5b;
    justify-content: center;
  }
  .average-content-font() {
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    //color: #ffffff;
    justify-content: center;
  }

  .case-statistics-container {
    width: 100%;
    height: 100%;
    position: relative;

    .measure-count {
      .title-font();
      position: absolute;
      width: 72px;
      height: 16px;
      left: 24px;
      top: 52px;
    }
    .abnormal-percentage {
      .title-font();
      position: absolute;
      width: 120px;
      height: 16px;
      left: 24px;
      top: 96px;
    }
    .info-block-title-1 {
      .info-block-title();
      position: absolute;
      width: 239px;
      height: 27px;
      left: 141px;
      top: 15px;
    }
    .info-block-title-2 {
      .info-block-title();
      position: absolute;
      width: 145px;
      height: 27px;
      left: 387px;
      top: 15px;
    }
    .info-block-title-3 {
      .info-block-title();
      position: absolute;
      width: 145px;
      height: 27px;
      left: 539px;
      top: 15px;
    }
    .info-block-title-4 {
      .info-block-title();
      position: absolute;
      width: 145px;
      height: 27px;
      left: 691px;
      top: 15px;
    }
    .info-block-title-5 {
      .info-block-title();
      position: absolute;
      width: 145px;
      height: 27px;
      left: 843px;
      top: 15px;
    }

    .info-block-1 {
      .info-block();
      position: absolute;
      width: 239px;
      height: 87px;
      left: 141px;
      top: 39px;
      .bloodPressure-count {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 85px;
        height: 16px;
        left: 12px;
        top: 12px;
      }
      .bloodPressure-percentage {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 41px;
        height: 20px;
        left: 30px;
        top: 56px;
      }
      .bloodPressure-arr-title {
        .arr-font();
        position: absolute;
        width: 79px;
        height: 73px;
        left: 104px;
        top: 8px;
      }
      .bloodPressure-arr {
        .arr-font();
        position: absolute;
        width: 50px;
        height: 73px;
        left: 192px;
        top: 8px;
      }
    }
    .info-block-2 {
      .info-block();
      position: absolute;
      width: 145px;
      height: 87px;
      left: 387px;
      top: 39px;
      .glucose-count {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 15px;
        left: 0px;
        top: 13px;
      }
      .glucose-percentage {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 41px;
        height: 20px;
        left: 54px;
        top: 56px;
      }
    }
    .info-block-3 {
      .info-block();
      position: absolute;
      width: 145px;
      height: 87px;
      left: 539px;
      top: 39px;
      .bodyWeight-count {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 16px;
        left: 0px;
        top: 12px;
      }
      .bodyWeight-percentage {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 30px;
        height: 20px;
        left: 59px;
        top: 56px;
      }
    }
    .info-block-4 {
      .info-block();
      position: absolute;
      width: 145px;
      height: 87px;
      left: 691px;
      top: 39px;
      .bodyFat-count {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 16px;
        left: 0px;
        top: 12px;
      }
      .bodyFat-percentage {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 20px;
        left: 0px;
        top: 56px;
      }
    }
    .info-block-5 {
      .info-block();
      position: absolute;
      width: 145px;
      height: 87px;
      left: 843px;
      top: 39px;
      .visceralFat-count {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 16px;
        left: 0px;
        top: 12px;
      }
      .visceralFat-percentage {
        .title-font();
        justify-content: center;
        position: absolute;
        width: 30px;
        height: 20px;
        left: 59px;
        top: 56px;
      }
    }

    .average-title-area {
      position: absolute;
      width: 981px;
      height: 32.38px;
      left: 7px;
      top: 138px;

      background: #f0fffe;
      border-radius: 10px 10px 0px 0px;
      .average-title-block-1 {
        position: absolute;
        width: 204px;
        height: 31.78px;
        left: 72px;
        top: 0px;

        background: #dbfefb;
        border-radius: 0px;
      }
      .average-title-block-2 {
        position: absolute;
        width: 382px;
        height: 33px;
        left: 425px;
        top: 0px;

        background: #dbfefb;
        border-radius: 0px;
      }
      .average-title-block-3 {
        position: absolute;
        width: 76px;
        height: 33px;
        left: 905px;
        top: 0px;

        background: #dbfefb;
        border-radius: 0px 10px 0px 0px;
      }
      .average-title-1 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 72px;
      }
      .average-title-2 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 123px;
      }
      .average-title-3 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 174px;
      }
      .average-title-4 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 224px;
      }
      .average-title-5 {
        .average-title-font();
        position: absolute;
        width: 38px; // from 50 -> 38
        height: 16.3px;
        top: 13px;
        left: 276px;
      }
      .average-title-6 {
        .average-title-font();
        position: absolute;
        width: 38px; // from 50 -> 38
        height: 16.3px;
        top: 13px;
        left: 326px;
      }
      .average-title-7 {
        .average-title-font();
        position: absolute;
        width: 38px; // from 50 -> 38
        height: 16.3px;
        top: 13px;
        left: 376px;
      }
      .average-title-8 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 435px;
      }
      .average-title-9 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 494px;
      }
      .average-title-10 {
        .average-title-font();
        position: absolute;
        width: 62px;
        height: 16px;
        top: 13px;
        left: 553px;
      }
      .average-title-11 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 624px;
      }
      .average-title-12 {
        .average-title-font();
        position: absolute;
        width: 72px;
        height: 16.3px;
        top: 13px;
        left: 671px;
      }
      .average-title-13 {
        .average-title-font();
        position: absolute;
        width: 72px;
        height: 16.3px;
        top: 13px;
        left: 735px;
      }
      .average-title-14 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 807px;
      }
      .average-title-15 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 857px;
      }
      .average-title-16 {
        .average-title-font();
        position: absolute;
        width: 50px;
        height: 16.3px;
        top: 13px;
        left: 915px;
      }
    }
    .average-content-area {
      position: absolute;
      height: 67px;
      width: 981px;
      left: 7px;
      top: 171px;
      background: #71d5c4;
      .average-content-block-1 {
        position: absolute;
        width: 204px;
        height: 67px;
        left: 71px;
        top: 0px;
        background: #62cab8;
      }
      .average-content-block-2 {
        position: absolute;
        width: 382px;
        height: 67px;
        left: 425px;
        top: 0px;
        background: #62cab8;
      }
      .average-content-block-3 {
        position: absolute;
        width: 76px;
        height: 67px;
        left: 905px;
        top: 0px;
        background: #62cab8;
      }
      .average-content-title {
        .average-content-font();
        position: absolute;
        width: 62px;
        height: 22px;
        left: 5px;
        top: 20px;
      }
      .average-content-1 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 72px;
        top: 22px;
      }
      .average-content-2 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 123px;
        top: 22px;
      }
      .average-content-3 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 173px;
        top: 22px;
      }
      .average-content-4 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 224px;
        top: 22px;
      }
      .average-content-5 {
        .average-content-font();
        position: absolute;
        width: 39.31px;
        height: 25px;
        left: 285px;
        top: 22px;
      }
      .average-content-6 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 326px;
        top: 22px;
      }
      .average-content-7 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 377px;
        top: 22px;
      }
      .average-content-8 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 436px;
        top: 22px;
      }
      .average-content-9 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 499px;
        top: 22px;
      }
      .average-content-10 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 562px;
        top: 22px;
      }
      .average-content-11 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 625px;
        top: 22px;
      }
      .average-content-12 {
        .average-content-font();
        position: absolute;
        width: 72px;
        height: 25px;
        left: 670px;
        top: 22px;
      }
      .average-content-13 {
        .average-content-font();
        position: absolute;
        width: 72px;
        height: 25px;
        left: 735px;
        top: 22px;
      }
      .average-content-14 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 807px;
        top: 22px;
      }
      .average-content-15 {
        .average-content-font();
        position: absolute;
        width: 14px;
        height: 25px;
        left: 877px;
        top: 22px;
      }
      .average-content-16 {
        .average-content-font();
        position: absolute;
        width: 50px;
        height: 25px;
        left: 918px;
        top: 22px;
      }
    }
  }
</style>
